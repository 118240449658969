import React from 'react'
import { Layout } from '../components/Layout'
import { Grid, Cell } from 'baseui/layout-grid'
import { ParagraphLarge } from 'baseui/typography'
import { HeadingLevel } from 'baseui/heading'
import { Heading } from '../components/Heading'
import { LinkButton } from '../components/LinkButton'
import { useStyletron } from 'baseui'

const PasswordResetConfirmationPage = () => {
  const [css] = useStyletron()
  return (
    <Grid>
      <Cell span={[4, 6]}>
        <HeadingLevel>
          <Heading> Reset password</Heading>
          <ParagraphLarge overrides={{ Block: { style: { marginBottom: '22px' } } }}>
            If your email matched an account in our system, your password reset e-mail has been
            sent.
          </ParagraphLarge>
          <LinkButton className={css({ width: '50%', display: 'inline-block' })} to="/login">
            Back to login
          </LinkButton>
        </HeadingLevel>
      </Cell>
    </Grid>
  )
}

const LayoutPasswordResetConfirmationPage = () => {
  return (
    <Layout showNav={false} searchBarAvailable={false} backButton={false}>
      <PasswordResetConfirmationPage />
    </Layout>
  )
}

export default LayoutPasswordResetConfirmationPage
